<template>
  <div style="background-color: #fafbfc">
    <Header type="" />
    <div class="report" v-for="(item,index) in targetData" :key="index">
      <div class="title">{{item.type}}</div>
      <Table v-if="item.type != '季度目标完成情况'" :columns="columns" :data="item.list" />
      <Table v-else :columns="columnsQuarter" :data="item.list" />
    </div>
  </div>
</template>

<script>
import Table from '@/components/table';
import Header from '@/components/header/headerWithNavigation/index';
import {fetchTarget} from "@/api/home";

export default {
  components: {
    Table,
    Header,
  },
  data() {
    return {
      columns: [
        { name: '类型', props: 'typeName' },
        { name: '目标', props: 'targetNumber' },
        { name: '销量', props: 'saleNumber' },
        { name: '完成率', props: 'rate' },
      ],
      columnsQuarter: [
        { name: '季度', props: 'seasonName' },
        { name: '类型', props: 'typeName' },
        { name: '目标', props: 'targetNumber' },
        { name: '销量', props: 'saleNumber' },
        { name: '完成率', props: 'rate' },
      ],
      targetData: [],
    }
  },
  mounted() {
    this.getTarget();
  },
  methods: {
    async getTarget() {
      const res = await fetchTarget();
      this.targetData = res.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.report {
  margin-top: 6px;
  .title {
    height: 30px;
    padding: 8px 0 8px 15px;
    font-size: 14px;
    background-color: #ffffff;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 1px;
    
  }
}
</style>