<template>
  <div class="content" >
    <div class="content_head">
      <div class="content_icon">
        <img src="../../assets/images/login/yellow_logo.png">
      </div>
      <div class="content_tittle">
        {{targetDate.year}}年度销售目标确认函
      </div>
    </div>
    <div class="content_text">
      <div class="content_text_content">
        <b>尊敬的 <span id="dealerName" style="text-decoration:underline">{{name}}</span> </b>:<br/>
        <span style="padding-left: 30px;">您好！史丹利南方营销系统顺应行业趋势，满足市场新需求，近两年特推出一系列高端新品，
        肥效得到用户们的一致好评。为进一步将新品好肥推广至更多的种植户，发挥产品更大的价值，
        现与贵单位针对2022新销售年度总销量目标及各类产品目标约定如下：</span><br/>
        <span style="padding-left: 30px;">1、贵单位2022销售年度销售总目标量为 <span id="totalTarget" class="content_span" v-text="targetDate.totalNumber"></span> 吨，核心产品销量目标为 <span id="coreTarget" class="content_span" v-text="targetDate.coreNumber"></span> 吨；</span><br/>
        <span style="padding-left: 30px;">2、贵单位2022销售年度新全水溶产品及三安噻虫嗪销量目标为 <span id="newTarget" class="content_span" v-text="targetDate.newNumber"></span> 吨；高端产品（特肥）销量目标为 <span id="specialTarget" class="content_span"  v-text="targetDate.specialNumber"></span></span> 吨。<br/>
        <span style="padding-left: 30px;">以上请贵单位务必仔细阅读及核对，如无疑问，请点击下方“确认”按钮。</span><br/>
        <p style="text-align:right;">
          <b>史丹利化肥销售有限公司</b><br/>
          <b id="dateStr" style="padding-right:40px;" v-text="targetDate.dateStr"></b>
        </p>
      </div>
    </div>
    <div style="text-align:center;">
<!--        <input type="submit" value=" 确 认 " @click="sure" style="background:yellow; font-weight:bold;"/>-->
      <Button
          v-if="isSure"
          class="custom-btn"
          @click="sure"
      > 确 认 </Button>
    </div>
  </div>
</template>
<script>
import * as user from '@/utils/user';
import { getTarget,sureTarget } from '@/api/login';
import { Button } from "vant";
export default {
    components: {
      Button,
    },
    data() {
        return {
            isSure:false,
            targetDate:{},
            name:user.getDealerName(),
        }
    },
    mounted() {
        //查询是否存在未确认的目标
        getTarget().then(res => {
          if (res.msg == '操作成功') {
            this.targetDate = res.data;
            this.isSure = true;
          }  
        })
    },
    methods: {
        sure(){
            sureTarget().then(res => {
                if(res.status == "0"){
                  this.$router.replace('/');
                }else{
                    this.$message({
                        showClose: true,
                        message: '目标确认失败',
                        type: 'error'
                    });
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .content{
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -10;
      background:url('../../assets/images/login/target_bg.png');
      background-repeat: no-repeat;
      background-position: 0px 0px;
      background-size: 100% 100%;

      .content_icon img{
        width: 25%;
        height: 23%;
        padding-top: 15px;
      }
      .content_tittle{
        font-size: 20px;
        font-weight: 600;
        color: yellow;
      }

      .content_text{
        width: 92%;
        height: 65%;
        margin: 5px auto;
        right: 0px;
        left: 0px;
        background:url('../../assets/images/login/target_content.png');
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-size: 100% 100%;
        padding-top: 30px;
        /*padding-top:240px;
        line-height:32px;*/

        .content_text_content{
          width: 80%;
          height: 90%;
          margin: auto;
          line-height:24px;
          text-align: left;
          font-size: 12px;
          overflow: auto;
        }
        .content_text_content::-webkit-scrollbar {
          border-width:1px;
        }
      }
      
      

      .content_span{
        text-decoration:underline;
        font-weight:bold;
      }

      .custom-btn {
        width: 20%;
        height: 30px;
        background: yellow;
        font-weight:bold;
        border: none;
        border-radius: 5px;
        padding: 0 18px;
      }
      
    }
    
    
    
</style>
