<template>
  <div class="content-certificate">
    <div class="title">
      <span class="text">为防范身份信息冒用，保障您的账户及资金安全，需要进行人脸识别以确保是您本人操作！</span>
    </div>
    <img class="img" src="../../assets/images/login/face.png">
    <Form @submit="onSubmit" ref="form">
      <Field
          v-model="form.certificateName"
          :rules="requiredRules"
          name="picker"
          label="姓名"
          right-icon="arrow"
          placeholder="请输入姓名或直接选择"
          @click-right-icon="showCertificateType = true"
      />
      <Popup v-model="showCertificateType" position="bottom">
        <Picker
            title="选择账号类型"
            show-toolbar
            :columns="certificateColumns"
            @confirm="onCertificateConfirm"
            @cancel="showCertificateType = false"
        />
      </Popup>
      <Field
          v-model="form.certificateNumber"
          :rules="requiredRules"
          label="身份证号"
          placeholder="请输入身份证号"
          @input="isEditNumber = true"
      />
      
      
     
    </Form>
    <CustomButton size="large" class="submit" @click="onSubmit">开始验证</CustomButton>
    <div class="loading-container" v-if="isLoading">
      <Loading />
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/common/button/CustomButton'
import { Form, Field,Picker,Popup,Loading } from 'vant';

import { getUserName } from "@/utils/user"
import { getCrtificateList,getVerificationBizToken } from '@/api/login';
import {fetchDealerLogout} from "@/api/common";
import * as user from "@/utils/user";


export default {
  components: {
    CustomButton,
    Form,
    Field,
    Picker,
    Popup,
    Loading
  },
  data() {
    return {
      isLoading: false,
      isEditNumber: false,
      openId: '',
      certificateColumns:[],
      showCertificateType: false,
      form:{
        certificateName: '',
        certificateNumber: '',
        certificateNumberFull: '',
      },

      requiredRules: [
          {
          required: true,
          trigger: 'onBlur'
        },
          
      ],

    }
  },
  mounted() {
    history.pushState(null, null, document.URL);

    window.addEventListener("popstate",function() {

      history.pushState(null, null, document.URL);
      fetchDealerLogout();
      user.logout()
      window.location.replace('/login')

    }, false);

  },
  created() {
    console.log(this.$route.params)
    console.log(this.$route.query)
    if (this.$route.params.openId) {
      this.openId = this.$route.params.openId;
    }
    
    this.fetchAccountType();
    
    
  },
  methods: {
    async fetchAccountType() {
      this.certificateColumns = [];
      let response = await getCrtificateList({
        loginName: getUserName()
      })
      response.data.forEach(item => {
        this.certificateColumns.push({
          "value":item.certificateNumber,
          "text":item.certificateName
        })
      });
    },

    
    onCertificateConfirm(value) {
      this.isEditNumber = false;
      this.showCertificateType = false;
      this.form.certificateName = value.text;
      this.form.certificateNumberFull = value.value;
      const reg = /^(.{6})(?:\d+)(.{4})$/;
      this.form.certificateNumber = value.value.replace(reg, '$1******$2');
    },

    
    async onSubmit() {
      var validate = false;
      await this.$refs.form.validate().then(() => {
        validate = true;
      }).catch(() => {
        // this.$toast.fail('请填写完整信息')
      })


      if (this.form.idCard && this.form.idCard.length != 18) {
        this.$toast('请录入18位身份证号码!')
        return;
      }
      
      if (validate) {
        this.isLoading = true;
        await getVerificationBizToken({
          loginName: getUserName(),
          openId: this.openId,
          certificateName: this.form.certificateName,
          certificateNumber: this.isEditNumber ? this.form.certificateNumber : this.form.certificateNumberFull 
        }).then(res => {
          console.log(res)
          window.location.href=res.data.url;
          this.isLoading = false;
        }).catch(err => {
          console.log(err)
          this.isLoading = false;
        });
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-certificate {
  
  .title {
    padding: 10px 15px;
    text-align: left;
    .text {
      font-size: 15px;
      color: #000000;
    }
  }
  .img {
    padding: 40px 0;
  }
  .form {
    padding-bottom: 64px;
  }
  .submit {
    margin-top: 50px;
    width: 90%;
    border-radius: 13px;

    //position: fixed;
    //bottom: 0;
    //left: 0;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    background-color: #848181;
    opacity:0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>